<template>
  <div class="filter-wrapper">
    <woot-date-range-picker
      show-range
      :value="customDateRange"
      :confirm-text="$t('REPORT.CUSTOM_DATE_RANGE.CONFIRM')"
      :placeholder="$t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER')"
      @change="onDateRangeChange"
    />
  </div>
</template>

<script>
import subDays from 'date-fns/subDays';
import { DATE_RANGE_OPTIONS } from '../../constants';
import { getUnixStartOfDay, getUnixEndOfDay } from 'shared/helpers/DateHelper';
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';

export default {
  components: {
    WootDateRangePicker,
  },

  data() {
    return {
      selectedDateRange: DATE_RANGE_OPTIONS.LAST_7_DAYS,
      selectedGroupByFilter: null,
      customDateRange: [new Date(), new Date()],
    };
  },
  computed: {
    to() {
      return getUnixEndOfDay(this.customDateRange[1]);
    },
    from() {
      return getUnixStartOfDay(this.customDateRange[0]);
    },
  },
  watch: {
    businessHoursSelected() {
      this.emitChange();
    },
  },
  mounted() {
    this.setInitialRange();
  },
  methods: {
    setInitialRange() {
      const { offset } = this.selectedDateRange;
      const fromDate = subDays(new Date(), offset);
      const from = getUnixStartOfDay(fromDate);
      const to = getUnixEndOfDay(new Date());
      this.$emit('filterChange', {
        from,
        to,
        ...this.selectedGroupByFilter,
      });
    },
    emitChange() {
      const { from, to } = this;
      this.$emit('filterChange', {
        from,
        to,
        ...this.selectedGroupByFilter,
      });
    },
    emitFilterChange(params) {
      this.selectedGroupByFilter = params;
      this.emitChange();
    },
    onDateRangeChange(value) {
      this.customDateRange = value;
      this.emitChange();
    },
  },
};
</script>
<style scoped>
.filter-wrapper {
  display: flex; /* flex */
  flex-direction: column; /* flex-col */
  flex-wrap: wrap; /* flex-wrap */
  width: 100%; /* w-full */
  gap: 0.75rem; /* gap-3 */
}

@media (min-width: 768px) {
  .filter-wrapper {
    flex-direction: row; /* md:flex-row (applies at medium screen sizes and up) */
  }
}
</style>

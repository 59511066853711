<template>
  <div class="conversation-container">
    <div class="conversation-details">
      <span class="conversation-id">{{ '#' + conversationId + ' ' }}</span>
      <span class="conversation-with">{{ $t('SLA_REPORTS.WITH') }}</span>
      <span class="contact-name">{{ conversation.contact.name }}</span>
      <CardLabels
        class="card-labels"
        :conversation-id="conversationId"
        :conversation-labels="conversationLabels"
      />
    </div>
    <div class="sla-name">{{ slaName }}</div>
    <div class="assignee">
      <UserAvatarWithName
        v-if="conversation.assignee"
        :user="conversation.assignee"
      />
      <span v-else class="no-assignee"> --- </span>
    </div>
    <SLAViewDetails :sla-events="slaEvents" />
  </div>
</template>

<script>
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName.vue';
import CardLabels from 'dashboard/components/widgets/conversation/conversationCardComponents/CardLabels.vue';
import SLAViewDetails from './SLAViewDetails.vue';

export default {
  components: {
    UserAvatarWithName,
    CardLabels,
    SLAViewDetails,
  },
  props: {
    slaName: {
      type: String,
      required: true,
    },
    conversationId: {
      type: Number,
      required: true,
    },
    conversation: {
      type: Object,
      required: true,
    },
    slaEvents: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    conversationLabels() {
      return this.conversation.labels
        ? this.conversation.labels.map(item => {
            return item.trim();
          })
        : [];
    },
  },
};
</script>
<style scoped>
.conversation-container {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4rem; /* h-16 */
  grid-template-columns: repeat(12, 1fr); /* grid-cols-12 */
  gap: 16px; /* gap-4 */
  padding: 0 24px; /* px-6 */
  background-color: #ffffff; /* bg-white */
  border-bottom: 1px solid #f8fafc; /* border-b border-slate-75 */
}

.conversation-container.dark {
  background-color: #0f172a; /* dark:bg-slate-900 */
  border-color: rgba(30, 41, 59, 0.5); /* dark:border-slate-800/50 */
}

.conversation-container:last-child {
  border-bottom: none; /* last:border-b-0 */
  border-radius: 0 0 0.75rem 0.75rem; /* last:rounded-b-xl */
}

.conversation-details {
  display: flex;
  align-items: center;
  gap: 8px; /* gap-2 */
  grid-column: span 6 / span 6; /* col-span-6 */
  padding: 0.5rem 0; /* py-2 */
  font-size: 15px; /* text-sm */
  letter-spacing: 0.5px; /* tracking-[0.5] */
  color: #334155; /* text-slate-700 */
}

.conversation-details.dark {
  color: #f1f5f9; /* dark:text-slate-100 */
}

.conversation-details .conversation-id {
  color: #334155; /* text-slate-700 */
}

.conversation-details .conversation-id.dark {
  color: #e2e8f0; /* dark:text-slate-200 */
}

.conversation-details .conversation-with {
  color: #475569; /* text-slate-600 */
}

.conversation-details .conversation-with.dark {
  color: #94a3b8; /* dark:text-slate-300 */
}

.conversation-details .contact-name {
  text-transform: capitalize; /* capitalize */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* truncate */
  color: #334155; /* text-slate-700 */
}

.conversation-details .contact-name.dark {
  color: #e2e8f0; /* dark:text-slate-200 */
}

.card-labels {
  width: 80%; /* w-[80%] */
}

.sla-name {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding: 0.5rem 0; /* py-2 */
  font-size: 15px; /* text-sm */
  letter-spacing: 0.5px; /* tracking-[0.5] */
  color: #334155; /* text-slate-700 */
  grid-column: span 2 / span 2; /* col-span-2 */
}

.sla-name.dark {
  color: #f8fafc; /* dark:text-slate-50 */
}

.assignee {
  display: flex;
  align-items: center;
  grid-column: span 2 / span 2; /* col-span-2 */
  gap: 8px; /* gap-2 */
}

.no-assignee {
  color: #475569; /* text-slate-600 */
}

.no-assignee.dark {
  color: #e2e8f0; /* dark:text-slate-200 */
}
</style>

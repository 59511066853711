<template>
  <div>
    <div class="table-container">
      <div class="table-header">
        <TableHeaderCell
          :span="6"
          :label="$t('SLA_REPORTS.TABLE.HEADER.CONVERSATION')"
        />
        <TableHeaderCell
          :span="2"
          :label="$t('SLA_REPORTS.TABLE.HEADER.POLICY')"
        />
        <TableHeaderCell
          :span="2"
          :label="$t('SLA_REPORTS.TABLE.HEADER.AGENT')"
        />
        <TableHeaderCell :span="2" label="" />
      </div>

      <div v-if="isLoading" class="loading-container">
        <Spinner />
        <span>{{ $t('SLA_REPORTS.LOADING') }}</span>
      </div>
      <div v-else-if="slaReports.length > 0">
        <SLAReportItem
          v-for="slaReport in slaReports"
          :key="slaReport.applied_sla.id"
          :sla-name="slaReport.applied_sla.sla_name"
          :conversation="slaReport.conversation"
          :conversation-id="slaReport.conversation.id"
          :sla-events="slaReport.sla_events"
        />
      </div>
      <div v-else class="no-records-container">
        {{ $t('SLA_REPORTS.NO_RECORDS') }}
      </div>
    </div>
    <TableFooter
      v-if="shouldShowFooter"
      :current-page="currentPage"
      :total-count="totalCount"
      :page-size="pageSize"
      @pageChange="onPageChange"
    />
  </div>
</template>

<script>
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';
import TableHeaderCell from 'dashboard/components/widgets/TableHeaderCell.vue';
import SLAReportItem from './SLAReportItem.vue';
import Spinner from '../../../../../../../shared/components/Spinner.vue';

export default {
  name: 'SLATable',
  components: {
    SLAReportItem,
    TableFooter,
    Spinner,
    TableHeaderCell,
  },
  props: {
    slaReports: {
      type: Array,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageNo: 1,
    };
  },
  computed: {
    shouldShowFooter() {
      return this.currentPage === 1
        ? this.totalCount > this.pageSize
        : this.slaReports.length > 0;
    },
  },
  methods: {
    onPageChange(page) {
      this.$emit('pageChange', page);
    },
  },
};
</script>

<style>
.table-container {
  min-width: 100%; /* min-w-full */
  border: 1px solid #f8fafc; /* border border-slate-75 */
  border-radius: 0.75rem; /* rounded-xl */
}

.table-container.dark {
  border-color: rgba(30, 41, 59, 0.5); /* dark:border-slate-700/50 */
}

.table-header {
  display: grid; /* grid */
  align-content: center; /* content-center */
  height: 3rem; /* h-12 */
  grid-template-columns: repeat(12, 1fr); /* grid-cols-12 */
  gap: 16px; /* gap-4 */
  padding: 0 24px; /* px-6 */
  border-bottom: 1px solid #f8fafc; /* border-b border-slate-75 */
  background-color: #f1f5f9; /* bg-slate-25 */
  border-radius: 0.75rem 0.75rem 0 0; /* rounded-t-xl */
}

.table-header.dark {
  border-color: rgba(30, 41, 59, 0.5); /* dark:border-slate-800 */
  background-color: #0f172a; /* dark:bg-slate-900 */
}

.loading-container,
.no-records-container {
  display: flex;
  align-items: center; /* items-center */
  justify-content: center; /* justify-center */
  height: 8rem; /* h-32 */
  background-color: #ffffff; /* bg-white */
  border-radius: 0 0 0.75rem 0.75rem; /* rounded-b-xl */
}

.loading-container.dark,
.no-records-container.dark {
  background-color: #0f172a; /* dark:bg-slate-900 */
}
</style>

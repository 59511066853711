<template>
  <div class="content-container">
    <span class="label-text">
      {{ label }}
      <fluent-icon
        v-tooltip.right="toolTip"
        size="14"
        icon="information"
        type="outline"
        class="icon"
      />
    </span>
    <div v-if="isLoading" class="loading-placeholder" />

    <span v-else class="value-text">
      {{ value }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    toolTip: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.content-container {
  display: flex;
  flex-direction: column;
  gap: 8px; /* 2 * 4px */
  align-items: flex-start;
  justify-content: center;
  min-width: 10rem; /* min-w-[10rem] */
}

.label-text {
  display: inline-flex;
  align-items: center;
  gap: 4px; /* 1 * 4px */
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  color: #334155; /* text-slate-700 */
}

.label-text.dark {
  color: #e2e8f0; /* dark:text-slate-200 */
}

.icon {
  flex-shrink: 0;
  font-size: 0.875rem; /* text-sm */
  font-weight: 400; /* font-normal */
  color: #64748b; /* text-slate-500 */
}

.icon.dark {
  color: #64748b; /* dark:text-slate-500 */
}

.loading-placeholder {
  width: 48px; /* w-12 */
  height: 24px; /* h-6 */
  margin-bottom: 2px; /* mb-0.5 */
  border-radius: 0.375rem; /* rounded-md */
  background-color: #f8fafc; /* bg-slate-50 */
  animation: pulse 1.5s infinite; /* animate-pulse */
}

.loading-placeholder.dark {
  background-color: #1f2937; /* dark:bg-slate-800 */
}

.value-text {
  font-size: 1.5rem; /* text-2xl */
  font-weight: 500; /* font-medium */
  color: #0f172a; /* text-slate-900 */
}

.value-text.dark {
  color: #f1f5f9; /* dark:text-slate-25 */
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>

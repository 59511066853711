<template>
  <div>
    <label>
      <span v-if="label">{{ label }}</span>
    </label>
    <woot-thumbnail
      v-if="src"
      size="80px"
      :src="src"
      :username="usernameAvatar"
    />
    <div v-if="src && deleteAvatar" class="avatar-delete-btn">
      <woot-button
        color-scheme="alert"
        variant="hollow"
        size="tiny"
        type="button"
        @click="onAvatarDelete"
      >
        {{ this.$t('INBOX_MGMT.DELETE.AVATAR_DELETE_BUTTON_TEXT') }}
      </woot-button>
    </div>
    <label class="margin-top-1 margin-bottom-1" :class="invalidAvatar">
      <input
        id="file"
        ref="file"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        :class="invalidAvatar"
        @change="handleImageUpload"
      />
      <slot />
    </label>
    <p v-if="invalidAvatar" :class="invalidAvatar">
      {{ this.$t('INBOX_MGMT.DELETE.LIMIT') }}
    </p>
  </div>
</template>

<script>
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import { MAXIMUM_OG_IMAGE_UPLOAD_SIZE } from 'shared/constants/articles';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    usernameAvatar: {
      type: String,
      default: '',
    },
    deleteAvatar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      invalidAvatar: false,
    };
  },
  watch: {},
  methods: {
    handleImageUpload(event) {
      this.invalidAvatar = null;
      const [file] = event.target.files;
      if (file && !checkFileSizeLimit(file, MAXIMUM_OG_IMAGE_UPLOAD_SIZE)) {
        this.invalidAvatar = 'invalid-avatar';
      } else {
        this.$emit('change', {
          file,
          url: file ? URL.createObjectURL(file) : null,
        });
      }
    },
    onAvatarDelete() {
      this.$refs.file.value = null;
      this.$emit('onAvatarDelete');
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-delete-btn {
  margin-top: var(--space-smaller);
  margin-bottom: var(--space-smaller);
}
.invalid-avatar {
  color: var(--r-500);
  border-color: var(--r-500);
  font-size: var(--font-size-micro);
}
</style>

import { frontendURL } from '../../../../helper/URLHelper';

const SettingsWrapper = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/sla'),
      component: SettingsWrapper,
      props: {
        headerTitle: 'SLA.HEADER',
        headerButtonText: 'SLA.ADD_ACTION',
        icon: 'document-list-clock',
      },
      children: [
        {
          path: '',
          name: 'sla_wrapper',
          redirect: 'list',
          roles: ['administrator'],
        },
        {
          path: 'list',
          name: 'sla_list',
          component: Index,
          roles: ['administrator'],
        },
      ],
    },
  ],
};

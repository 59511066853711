var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'flex',
    'items-center',
    'px-0',
    'py-2',
    'text-xs',
    'font-medium',
    'text-left',
    'uppercase',
    'text-slate-700',
    'dark:text-slate-100',
    'rtl:text-right',
    _vm.spanClass ]},[_vm._t("default",[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }
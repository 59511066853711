<template>
  <div class="metrics-container">
    <SLAMetricCard
      :label="$t('SLA_REPORTS.METRICS.HIT_RATE.LABEL')"
      :value="hitRate"
      :tool-tip="$t('SLA_REPORTS.METRICS.HIT_RATE.TOOLTIP')"
      :is-loading="isLoading"
    />

    <div class="divider" />
    <SLAMetricCard
      :label="$t('SLA_REPORTS.METRICS.NO_OF_MISSES.LABEL')"
      :value="noOfBreaches"
      :tool-tip="$t('SLA_REPORTS.METRICS.NO_OF_MISSES.TOOLTIP')"
      :is-loading="isLoading"
    />
    <div class="divider" />
    <SLAMetricCard
      :label="$t('SLA_REPORTS.METRICS.NO_OF_CONVERSATIONS.LABEL')"
      :value="noOfConversations"
      :tool-tip="$t('SLA_REPORTS.METRICS.NO_OF_CONVERSATIONS.TOOLTIP')"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import SLAMetricCard from './SLAMetricCard.vue';

export default {
  components: {
    SLAMetricCard,
  },
  props: {
    hitRate: {
      type: String,
      required: true,
    },
    noOfBreaches: {
      type: Number,
      required: true,
    },
    noOfConversations: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.metrics-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px; /* gap-4 */
  background-color: #ffffff; /* bg-white */
  border-radius: 0.75rem; /* rounded-xl */
  border: 1px solid #f8fafc; /* border-slate-75 */
  padding: 24px; /* px-6 py-4 */
}

.metrics-container.dark {
  background-color: #0f172a; /* dark:bg-slate-900 */
  border-color: rgba(100, 116, 139, 0.5); /* dark:border-slate-700/50 */
}

@media (min-width: 640px) {
  .metrics-container {
    flex-direction: row;
    gap: 56px; /* sm:gap-14 */
  }
}

.divider {
  width: 100%;
  height: 100%;
  border: 1px solid #f8fafc; /* border-slate-75 */
}

.divider.dark {
  border-color: rgba(100, 116, 139, 0.5); /* dark:border-slate-700/50 */
}

@media (min-width: 640px) {
  .divider {
    width: 1px; /* sm:w-px */
  }
}
</style>

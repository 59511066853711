<template>
  <div class="woot-table">
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 17.4rem)"
      scroll-width="60rem"
      :columns="columns"
      :table-data="items"
      :border-around="false"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
import accountMixin from 'dashboard/mixins/account';
import adminMixin from '../../../../mixins/isAdmin';
import Thumbnail from '../../../../components/widgets/Thumbnail';

export default {
  components: {
    VeTable,
    // eslint-disable-next-line vue/no-unused-components
    Thumbnail,
  },
  mixins: [accountMixin, adminMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    searchText: {
      type: String,
      default: '',
    },
    loading: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      currentUserId: 'getCurrentUserID',
    }),
    columns() {
      return [
        {
          field: 'name',
          key: 'name',
          title: this.$t('MACROS.LIST.TABLE_HEADER')[0],
          align: 'start',
          width: 120,
          renderBodyCell: ({ row }) => {
            return <span style="word-break: break-word;">{row.name}</span>;
          },
        },
        {
          field: 'created_by',
          key: 'created_by',
          title: this.$t('MACROS.LIST.TABLE_HEADER')[1],
          align: 'start',
          renderBodyCell: ({ row }) => {
            if (!row?.created_by) {
              return null;
            }
            return (
              <div class="avatar-container text-ellipsis">
                <Thumbnail username={row?.created_by?.name} size="24px" />
                <span>{row?.created_by?.name}</span>
              </div>
            );
          },
        },
        {
          field: 'last_updated_by',
          key: 'last_updated_by',
          title: this.$t('MACROS.LIST.TABLE_HEADER')[2],
          align: 'start',
          renderBodyCell: ({ row }) => {
            if (!row?.updated_by) {
              return null;
            }
            return (
              <div class="avatar-container text-ellipsis">
                <Thumbnail username={row.updated_by?.name} size="24px" />
                <span>{row.updated_by?.name}</span>
              </div>
            );
          },
        },
        {
          field: 'visibility',
          key: 'visibility',
          title: this.$t('MACROS.LIST.TABLE_HEADER')[3],
          align: 'start',
          renderBodyCell: ({ row }) => {
            const visibility = this.visibilityLabel(row);
            return <span>{visibility}</span>;
          },
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          align: 'right',
          renderBodyCell: ({ row, rowIndex }) => {
            return (
              <div class="button-wrapper">
                <woot-button
                  v-tooltip={this.$t('MACROS.EDIT.TOOLTIP')}
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  class-names="grey-btn"
                  icon="edit"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  fill-color="none"
                  onClick={() => this.$emit('editMacro', row)}
                />

                <woot-button
                  v-tooltip={this.$t('MACROS.DELETE.TOOLTIP')}
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  onClick={() => this.$emit('deleteMacro', row, rowIndex)}
                />
              </div>
            );
          },
        },
      ];
    },
  },
  methods: {
    visibilityLabel(macro) {
      return macro.visibility === 'global'
        ? this.$t('MACROS.EDITOR.VISIBILITY.GLOBAL.LABEL')
        : this.$t('MACROS.EDITOR.VISIBILITY.PERSONAL.LABEL');
    },
  },
};
</script>
<style lang="scss">
@import '../../../../../dashboard/assets/scss/widgets/_woot-tables.scss';

.avatar-container {
  display: flex;
  align-items: center;

  span {
    margin-left: var(--space-small);
    margin-right: var(--space-small);
  }
}
</style>

<template>
  <div class="container">
    <span class="label">{{ label }}</span>
    <div class="items-container">
      <span v-for="item in items" :key="item.id" class="item">
        {{ formatDate(item.created_at) }}
      </span>
      <slot name="showMore" />
    </div>
  </div>
</template>

<script>
import { format, fromUnixTime } from 'date-fns';

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(timestamp) {
      return format(fromUnixTime(timestamp), 'MMM dd, yyyy, hh:mm a');
    },
  },
};
</script>
<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.label {
  font-size: 12px;
  position: sticky;
  top: 0;
  height: fit-content;
  min-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #64748b;
}

.dark-mode .label {
  color: #e2e8f0;
}

.items-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}

.item {
  font-size: 0.875rem;
  font-weight: 400;
  color: #0f172a;
  text-align: right;
  font-feature-settings: 'tnum';
}
</style>

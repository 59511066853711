<template>
  <div
    v-on-clickaway="closeSlaEvents"
    class="label-wrap"
    @mouseleave="closeSlaEvents"
  >
    <div class="popup-wrapper">
      <div class="relative">
        <woot-button
          color-scheme="secondary"
          variant="link"
          @click="openSlaEvents"
        >
          {{ $t('SLA_REPORTS.TABLE.VIEW_DETAILS') }}
        </woot-button>
        <SLAPopoverCard
          v-if="showSlaPopoverCard"
          :sla-missed-events="slaEvents"
          class="right-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import SLAPopoverCard from 'dashboard/components/widgets/conversation/components/SLAPopoverCard.vue';

export default {
  components: {
    SLAPopoverCard,
  },
  mixins: [clickaway],
  props: {
    slaEvents: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      showSlaPopoverCard: false,
    };
  },

  methods: {
    closeSlaEvents() {
      this.showSlaPopoverCard = false;
    },
    openSlaEvents() {
      this.showSlaPopoverCard = !this.showSlaPopoverCard;
    },
  },
};
</script>
<style>
.popup-wrapper {
  display: flex; /* flex */
  align-items: center; /* items-center */
  grid-column: span 2 / span 2; /* col-span-2 */
  padding-left: 0; /* px-0 */
  padding-right: 0; /* px-0 */
  padding-top: 0.5rem; /* py-2 */
  padding-bottom: 0.5rem; /* py-2 */
  font-size: 0.875rem; /* text-sm */
  letter-spacing: 0.5px; /* tracking-[0.5] */
  color: #334155; /* text-slate-700 */
}

.dark-mode .popup-wrapper {
  color: #f1f5f9; /* dark:text-slate-100 */
}

[dir='rtl'] .popup-wrapper {
  text-align: right; /* rtl:text-right */
}
.relative {
  position: relative;
}
.right-0 {
  right: 0;
}
</style>

<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('CANNED_MGMT.HEADER_BTN_TXT') }}
    </woot-button>
    <div class="search-container">
      <search-input
        :value="searchValue"
        :placeholder="$t('CANNED_MGMT.NOT_FOUND.PLACEHOLDER')"
        @update:value="handleChange"
      />
    </div>
    <!-- List Canned Response -->
    <div class="row table-view">
      <div class="small-8 columns with-right-space ">
        <p
          v-if="!uiFlags.fetchingList && !records.length && !searchValue.length"
          class="no-items-error-message"
        >
          {{ $t('CANNED_MGMT.LIST.404') }}
        </p>
        <no-result-card
          v-else-if="!records.length && searchValue.length > 0 && !isSearching"
          :title="$t('CANNED_MGMT.NOT_FOUND.TITLE')"
          :description="$t('CANNED_MGMT.NOT_FOUND.DESCRIPTION')"
        />
        <woot-loading-state
          v-if="uiFlags.fetchingList || isSearching"
          :message="$t('CANNED_MGMT.LOADING')"
        />

        <canned-table
          v-if="!uiFlags.fetchingList && records.length && !isSearching"
          :items="records"
          :search-text="searchValue"
          @editCanned="openEditPopup"
          @deleteCanned="openDeletePopup"
        />

        <table-footer
          :current-page="Number(meta.currentPage)"
          :total-count="meta.count"
          :page-size="20"
          @page-change="onPageChange"
        />
      </div>

      <div class="small-4 columns">
        <span v-dompurify-html="$t('CANNED_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-canned
        :on-close="hideAddPopup"
        @createdCanned="fetchCanneds({ page: meta.currentPage })"
      />
    </woot-modal>

    <!-- Edit Canned Response -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-canned
        v-if="showEditPopup"
        :id="selectedResponse.id"
        :edshort-code="selectedResponse.short_code"
        :edcontent="selectedResponse.content"
        :on-close="hideEditPopup"
      />
    </woot-modal>

    <!-- Delete Canned Response -->
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CANNED_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddCanned from './AddCanned';
import EditCanned from './EditCanned';
import urlMixin from 'dashboard/mixins/urlMixin';
import CannedTable from './CannedTable';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import SearchInput from '../../../../components/ui/SearchInput.vue';
import NoResultCard from '../../../../components/ui/NoResultCard.vue';
import _ from 'lodash';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    NoResultCard,
    SearchInput,
    AddCanned,
    EditCanned,
    CannedTable,
    TableFooter,
  },
  mixins: [urlMixin, alertMixin],
  data() {
    return {
      loading: {},
      isSearching: false,
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      cannedResponseAPI: {
        message: '',
      },
      searchValue: this.$route.query.search || '',
    };
  },
  computed: {
    ...mapGetters({
      records: 'getCannedResponses',
      meta: 'getMeta',
      uiFlags: 'getUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteRejectText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedResponse.short_code}?`;
    },
  },
  mounted() {
    // Fetch API Call
    this.fetchCanneds({
      page: this.meta.currentPage,
      searchKey: this.searchValue,
    });
  },
  methods: {
    onSearch(searchKey) {
      this.fetchCanneds({ searchKey, page: 1 });
    },
    handleChange(e) {
      this.searchValue = e;
      this.isSearching = true;
      this.searchData();
    },
    // eslint-disable-next-line func-names
    searchData: _.debounce(function() {
      this.onSearch(this.searchValue);
    }, 500),
    onPageChange(page) {
      this.fetchCanneds({ page, searchKey: this.searchValue });
    },
    fetchCanneds(values) {
      this.updatePageParamSearch(values.page, this.searchValue);
      this.$store.dispatch('getCannedResponse', {
        page: values.page,
        searchKey: values?.searchKey || this.searchValue,
      });
      this.isSearching = false;
    },
    setAlert(message, type) {
      // Reset loading, current selected agent
      this.loading[this.selectedResponse.id] = false;
      this.selectedResponse = {};
      // Show message
      this.cannedResponseAPI.message = message;
      this.showAlert(message, type);
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Modal Functions
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedResponse = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Modal Functions
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteCannedResponse(this.selectedResponse.id);
    },
    deleteCannedResponse(id) {
      this.$store
        .dispatch('deleteCannedResponse', id)
        .then(() => {
          this.setAlert(
            this.$t('CANNED_MGMT.DELETE.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.fetchCanneds({
            page: this.meta.currentPage,
            searchKey: this.searchValue,
          });
        })
        .catch(error => {
          const errorMessage =
            error?.message || this.$t('CANNED_MGMT.DELETE.API.ERROR_MESSAGE');
          this.setAlert(errorMessage, 'error');
        });
    },
  },
};
</script>
<style scoped>
.short-code {
  width: 14rem;
}
.wrap-break-words {
  word-break: break-all;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-container {
  margin-top: var(--space-smaller);
  margin-bottom: var(--space-two);
}
</style>

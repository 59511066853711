<template>
  <div
    :class="[
      'flex',
      'items-center',
      'px-0',
      'py-2',
      'text-xs',
      'font-medium',
      'text-left',
      'uppercase',
      'text-slate-700',
      'dark:text-slate-100',
      'rtl:text-right',
      spanClass,
    ]"
  >
    <slot>
      {{ label }}
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    span: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    spanClass() {
      if (this.span === 1) return 'col-span-1';
      if (this.span === 2) return 'col-span-2';
      if (this.span === 3) return 'col-span-3';
      if (this.span === 4) return 'col-span-4';
      if (this.span === 5) return 'col-span-5';
      if (this.span === 6) return 'col-span-6';
      if (this.span === 7) return 'col-span-7';
      if (this.span === 8) return 'col-span-8';
      if (this.span === 9) return 'col-span-9';
      if (this.span === 10) return 'col-span-10';

      return 'col-span-1';
    },
  },
};
</script>
<style scoped>
.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-6 {
  grid-column: span 6 / span 6;
}
.col-span-7 {
  grid-column: span 7 / span 7;
}
.col-span-8 {
  grid-column: span 8 / span 8;
}
.col-span-9 {
  grid-column: span 9 / span 9;
}
.col-span-10 {
  grid-column: span 10 / span 10;
}

/* Flexbox and other styles */
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-medium {
  font-weight: 500;
}

.text-slate-700 {
  color: #374151; /* Dark gray text */
}

.dark .text-slate-100 {
  color: #f1f5f9; /* Light gray text in dark mode */
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>
